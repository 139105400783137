<template>
    <div class="detail-paper">
        <div class="student-info">
            <div class="student-info-left">
                <div class="left-img">
                    <img v-if="answerData.avatar" :src="answerData.avatar" alt="">
                    <img v-else src="../../assets/images/teacher/default-header.png" alt="">
                </div>
            </div>
            <div class="student-info-right">
                <div class="info-right-top">
                    <span class="text-overflow">姓名：{{answerData.nickname}}</span>
                    <span>性别：{{answerData.gender}}</span>
                    <span>身份证号：{{answerData.id_number}}</span>
                </div>
                <div class="info-right-bottom">
                    <span class="text-overflow">考试名称：{{answerData.exam_name}}</span>
                    <span>考试时间：{{answerData.start_time}}</span>
                </div>
            </div>
        </div>
        <div class="paper-table">
            <div class="paper-total">
                <div class="total">
                    <span>试卷总分</span>
                    <span><strong>{{answerData.paper_total_score}}</strong>分</span>
                </div>
                <div class="score">
                    <span>试卷得分</span>
                    <span :style="answerData.stu_total_score > 60 ? '' : 'color: #FF0000'"><strong>{{answerData.stu_total_score}}</strong>分</span>
                </div>
            </div>
            <div class="answer-case">
                <div class="case-item"></div>
                <div class="case-item">答对</div>
                <div class="case-item">答错</div>
                <div class="case-item">未答</div>
                <div class="case-item">得分</div>
            </div>
            <div class="topic-type" v-for="item in questionDetail">
                <div class="type-item">{{item.name | formatHeadLineType}}<strong
                        class="text-gray">({{item.total}}道题)</strong></div>
                <div class="type-item">{{item.true_num}}道</div>
                <div class="type-item" style="color: #FF0000">{{item.false_num}}道</div>
                <div class="type-item">{{item.unanswer_sum}}道</div>
                <div class="type-item">{{item.stu_score}}</div>
            </div>
<!--            <div class="topic-type" v-if="answerData.askAnswer_data">-->
<!--                <div class="answer-item">问答题<strong class="text-gray">({{answerData.askAnswer_data.sum}}道题)</strong>-->
<!--                </div>-->
<!--                <div class="answer-item">{{answerData.askAnswer_data.score}}分</div>-->
<!--                &lt;!&ndash;          <div class="answer-item" v-else>-</div>&ndash;&gt;-->
<!--            </div>-->
<!--            <div class="topic-type" v-else>-->
<!--                <div class="answer-item">问答题<strong class="text-gray">(0道题)</strong></div>-->
<!--                <div class="answer-item">0分</div>-->
<!--                &lt;!&ndash;          <div class="answer-item" v-else>-</div>&ndash;&gt;-->
<!--            </div>-->
        </div>
        <div class="paperDetail-content">
            <div class="questionType-item" v-for="(headlineItem, headlineKey, headlineIndex) in answerData.topics"
                 :key="`bottom_data_${headlineIndex}`">
                <div class="questionType-title" v-if="headlineItem.list.length > 0">
                    <div class="green-block"></div>
                    <span class="title">{{headlineKey | formatHeadLineType}}</span>
                    <span class="subtitle">(共{{headlineItem.total || answerData.topics[headlineKey].total}}题)</span>
                </div>
                <div class="question-item" v-for="(questionItem, questionIndex) in headlineItem.list"
                     :key="`bottom_data_${questionIndex}`">
                    <div class="question-title">
                        <span>{{questionIndex + 1}}.</span>
                        <span style="font-weight: 500">【{{headlineKey | formatHeadLineType}}】</span>
                        <span class="title" v-html="questionItem.title"></span>
                        <span style="margin-left: 10px;color: #666">({{questionItem.score}}分)</span>
                    </div>
                    <div class="answer-box">
                        <!--      单选或者判断      -->
                        <template>
                            <el-radio-group v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'"
                                            v-model="questionItem.stu_answer[0]" class="question-answer">
                                <el-radio :disabled="true" :label="answerIndex" class="answer-item"
                                          v-for="(answerItem, answerIndex) in questionItem.options">
                                    <span>{{answerIndex + 1 | formatNumber}}、</span>{{answerItem.name}}
                                </el-radio>
                            </el-radio-group>
                            <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                                 v-if="headlineKey === 'single_data' || headlineKey === 'judge_data'"
                                 style="justify-content: space-between">
                                <div class="question-analysis-left">
                                    <span>正确答案：<strong class="strong-correct">{{questionItem.answer[0] + 1 | formatNumber}}</strong></span>
                                    <span>考生答案：<strong
                                            :class="questionItem.is_true === 1? 'strong strong-correct' : 'strong strong-error'">{{questionItem.stu_answer[0] + 1 | formatNumber}}</strong></span>
                                    <span>得分：{{questionItem.score}}分</span>
                                </div>
                                <div class="isAnalysis">
                                    <span @click="questionItem.isAnalysisShow = !questionItem.isAnalysisShow">{{questionItem.isAnalysisShow? '收起解析' : '查看解析'  }}</span>
                                </div>
                                <div class="answer-judge answer-judge-correct" v-if="questionItem.is_true === 1">回答正确
                                </div>
                                <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                            </div>
                        </template>
                        <!--     多选       -->
                        <template>
                            <el-checkbox-group :disabled="true" v-if="headlineKey === 'multiple_data'"
                                               v-model="questionItem.stu_answer" class="question-answer">
                                <el-checkbox :label="answerIndex" class="answer-item"
                                             v-for="(answerItem, answerIndex) in questionItem.options">
                                    {{answerIndex + 1 | formatNumber}}、{{answerItem.name}}
                                </el-checkbox>
                            </el-checkbox-group>
                            <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                                 v-if="headlineKey === 'multiple_data'" style="justify-content: space-between">
                                <div class="question-analysis-left">
                                    <span>正确答案：<strong class="strong-correct"
                                                       v-for="(correctItem,correctIndex) in questionItem.answer">{{correctItem + 1 | formatNumber}}</strong></span>
                                    <span>考生答案：<strong class="strong strong-correct"
                                                       v-for="(stuAnswertItem,stuAnswertIndex) in questionItem.stu_answer">{{stuAnswertItem + 1 | formatNumber}}</strong></span>
                                    <span>得分：{{questionItem.score}}分</span>
                                </div>
                                <div class="isAnalysis">
                                    <span @click="questionItem.isAnalysisShow = !questionItem.isAnalysisShow">{{questionItem.isAnalysisShow? '收起解析' : '查看解析'  }}</span>
                                </div>
                                <div class="answer-judge answer-judge-correct" v-if="questionItem.is_true === 1">回答正确
                                </div>
                                <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                            </div>
                        </template>
                        <!--      填空      -->
                        <div class="question-answer"
                             style="display: flex;margin-top: 20px; flex-direction:row;align-items: start "
                             v-if="headlineKey === 'fillBlank_data'">
                            <div class="answer-item" style="margin-top: 0;">我的答案：</div>
                            <div class="answer-content">
                                <template>
                                    <div class="answer-item answer-after"
                                         style="margin-top: 0;padding: 0;line-height: 19px"
                                         v-for="(answerItem, answerIndex) in questionItem.stu_answer">
                                        {{answerIndex+1}}.<strong v-if="questionItem.is_order_answer == 1"
                                                                  :style="questionItem.stu_answer[answerIndex] === questionItem.options[answerIndex].name ? 'color:#1AB163;font-weight:400':'color:#FF0000;font-weight:400'">{{answerItem}}</strong>
                                        <strong v-if="questionItem.is_order_answer == 0 && questionItem.copy_options"
                                                :style="questionItem.copy_options.includes(answerItem) ? 'color:#1AB163;font-weight:400':'color:#FF0000;font-weight:400'">{{answerItem}}</strong>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div :class="questionItem.is_true === 1 ? 'question-analysis question-analysis-correct' : 'question-analysis question-analysis-error'"
                             v-if="headlineKey === 'fillBlank_data'" style="justify-content: space-between">
                            <div class="question-analysis-left">
                                <span>正确答案：<strong class="strong-correct strong-after" style="color: #333"
                                                   v-for="(rightAnswerItem, rightAnswerIndex) in questionItem.options">{{rightAnswerIndex+1}}.{{rightAnswerItem.name}}</strong></span>
                                <span>得分：{{questionItem.score}}分</span>
                            </div>
                            <div class="isAnalysis">
                                <span @click="questionItem.isAnalysisShow = !questionItem.isAnalysisShow">{{questionItem.isAnalysisShow? '收起解析' : '查看解析'  }}</span>
                            </div>
                            <div class="answer-judge answer-judge-correct" v-if="questionItem.is_true === 1 ">回答正确</div>
                            <div class="answer-judge answer-judge-error" v-else>回答错误</div>
                        </div>
                        <!--      问答      -->
                        <div class="question-answer flex" style="flex-direction: row"
                             v-if="headlineKey === 'askAnswer_data'">
                            <div class="answer-item" style="line-height: 40px">学生答案：</div>
                            <div class="answer-content" style="width: 1%;display: flex;flex: 1;height: 100%">
                                <div class="answer-item" style="width: 100%;">
                                    <el-input type="textarea" :readonly="true" resize="none"
                                              :autosize="{ minRows: 4, maxRows: 4}"
                                              v-model="questionItem.stu_answer[0]" style="width:100%"></el-input>
                                </div>
                            </div>
                        </div>
                        <div class="answer-reference" v-if="headlineKey === 'askAnswer_data'">
                            <div class="reference-title">参考答案：</div>
                            <div class="htmlP" v-html="questionItem.analysis"></div>
                            <div class="reference-score">
                                <div>
                                    得分
                                    <el-input :max="questionItem.score" :readonly="true"
                                              v-model="questionItem.score"
                                              style="width: 90px;margin: 0 10px;"></el-input>
                                    分
                                </div>
                                <div style="margin-left: 34px">
                                    <el-button v-if="$route.query.isGrade" type="primary"
                                               @click="openBtn(questionItem.theory_topic_id)">评分详情
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="isAnalysis-content" v-show="questionItem.isAnalysisShow">
                        <span class="title">题目解析：</span>
                        <span class="analysis-span" v-html="questionItem.analysis"></span>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :title="`评分管理${dialogTitle}`"
                custom-class="student-dialog"
                :visible.sync="DialogVisible"
                @close="closeGrade"
                @opened="openGrade"
                width="830px">
            <el-table class="customTable" :data="markList" style="width: 100%; flex: 1;border: 1px solid #EEEEEE;"
                      height="1%" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '40px', color: '#333', background: '#F2F3FA',fontSize: '14px'}"
                      :cell-style="{fontSize: '14px',color: '#333333',height: '46px'}">
                <el-table-column prop="judge_name" align="center" label="评委名称"></el-table-column>
                <el-table-column prop="score" align="center" label="分数"></el-table-column>
                <el-table-column prop="addtime" align="center" label="评分时间"></el-table-column>
            </el-table>
            <el-pagination class="pager-center" style="margin-top: 20px;text-align: center"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="listCurrentChange">
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
    import {theoryexamMarkDetail} from '@/utils/apis'

    export default {
        name: "StudentAnswerDetail",
        props: ['answerData'],
        data() {
            return {
                questionDetail: [],
                dialogTitle: '',
                DialogVisible: false,
                userId: this.$route.query.id,
                examId: this.$route.query.examId,
                topicId: null,
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                markList: []
            }
        },
        filters: {
            // 题型
            formatHeadLineType(type) {
                const types = {
                    'single_data': '单选题',
                    'multiple_data': '多选题',
                    'judge_data': '判断题',
                    'fillBlank_data': '填空题',
                    'askAnswer_data': '问答题',
                }
                return types[type]
            },
            // 试题答案格式
            formatNumber(nums) {
                const numbers = {
                    '1': 'A',
                    '2': 'B',
                    '3': 'C',
                    '4': 'D',
                    '5': 'E',
                    '6': 'F',
                }
                return numbers[nums]
            },
        },
        mounted() {
            let arr2 = ['single_data', 'multiple_data', 'judge_data'];
            let questionObj = {};
            arr2.forEach(item => {
                let obj = {
                    false_num: 0,
                    stu_score: 0,
                    total: 0,
                    true_num: 0,
                    unanswer_sum: 0,
                    name: item
                }
                questionObj[item] = obj;
                if (this.answerData.topics[item]) {
                    questionObj[item].total = this.answerData.topics[item].total;
                    questionObj[item].true_num = this.answerData.topics[item].true_num;
                    questionObj[item].false_num = this.answerData.topics[item].false_num;
                    let unanswer_sum = this.answerData.topics[item].total - (this.answerData.topics[item].true_num + this.answerData.topics[item].false_num)
                    questionObj[item].unanswer_sum = unanswer_sum;
                    questionObj[item].stu_score = this.answerData.topics[item].stu_score;
                }
            })
            for (let i in questionObj) {
                this.questionDetail.push(questionObj[i])
            }
            //删除填空和问答
            delete this.answerData.topics.fillBlank_data
            delete this.answerData.topics.answer_data
            // if (this.answerData.topics.fillBlank_data && this.answerData.topics.fillBlank_data.list) {
            //     this.answerData.topics.fillBlank_data.list.forEach(item => {
            //         let arr = [];
            //         if (item.options) {
            //             item.options.forEach(item1 => {
            //                 arr.push(item1.name)
            //             })
            //         }
            //         item.copy_options = arr
            //     })
            // }
        },
        methods: {
            openBtn(topicId) {
                this.topicId = topicId;
                this.DialogVisible = true;
            },
            // 评分管理弹框关闭
            closeGrade() {
                this.listPages.currentPageNum = 1;
            },
            // 评分管理打开
            openGrade() {
                let params = {
                    exam_id: this.examId,
                    user_id: this.userId,
                    theory_topic_id: this.topicId,
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum
                }
                theoryexamMarkDetail(params).then((res) => {
                    this.markList = res.data.list;
                    this.listPages.total = res.data.sum;
                    switch (res.data.give_type) {
                        case 1:
                            this.dialogTitle = '（将学生平均分配给教师，最终分数为该教师评分的分数）';
                            break;
                        case 2:
                            this.dialogTitle = '（将学生平均分配给评委，最终分数为该评委评分的分数）';
                            break;
                        case 3:
                            this.dialogTitle = '（选择评分评委同时对学生评分，取平均分做该学生的分数）';
                            break;
                        case 4:
                            this.dialogTitle = '（选择评分评委同时对学生评分，去掉最高分和最低分，取剩下分数的平均分做该学生的分数）';
                            break;
                    }
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            listCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.openGrade();
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        padding: 0;
        margin: 0;
    }

    .detail-paper {
        display: flex;
        flex-direction: column;
        padding: 38px 20px;
        box-sizing: border-box;

        .student-info {
            display: flex;

            .student-info-left {
                .left-img {
                    width: 100px;
                    height: 140px;
                    box-sizing: border-box;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .student-info-right {
                display: flex;
                flex: 1;
                width: 1%;
                flex-direction: column;
                justify-content: space-between;
                padding: 5px 0 5px 20px;
                box-sizing: border-box;

                .info-right-top, .info-right-bottom {
                    padding: 0 64px;
                    background: #F3FFF8;
                    height: 50px;
                    display: flex;
                    align-items: center;

                    span {
                        font-size: 18px;
                        display: inline-block;
                        width: 30%;
                    }

                    &.info-right-bottom {
                        span {
                            &:first-of-type {
                                width: 60%;
                            }
                        }
                    }
                }
            }
        }

        .paper-table {
            display: flex;
            margin: 24px 0;

            .paper-total {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;

                .total, .score {
                    display: flex;
                    flex-direction: column;
                    padding: 24px 59px;
                    align-items: center;

                    span {
                        line-height: 1;

                        &:nth-child(1) {
                            font-size: 18px;
                            font-weight: 400;
                        }

                        &:nth-child(2) {
                            margin-top: 20px;
                            font-size: 18px;
                            font-weight: 400;

                            strong {
                                font-size: 24px;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .score {
                    border-top: 1px solid #EAEAEA;
                    /*span:nth-child(2){*/
                    /*    color: #FF0000;*/
                    /*}*/
                }
            }

            .answer-case {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;
                border-left: none;

                .case-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;
                    padding: 0px 40px;
                    border-bottom: 1px solid #EAEAEA;
                    box-sizing: border-box;
                    font-size: 18px;
                    font-weight: 400;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .topic-type {
                display: flex;
                flex-direction: column;
                border: 1px solid #EAEAEA;
                border-left: none;
                flex: 1;

                .type-item {
                    padding: 0px 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;
                    border-bottom: 1px solid #EAEAEA;
                    box-sizing: border-box;
                    font-size: 18px;
                    font-weight: 400;

                    .text-gray {
                        font-weight: 400;
                        color: #999999;
                    }

                    &:last-child {
                        border-bottom: none;
                        color: #333;
                    }

                    &:nth-child(3) {
                        color: #FF0000;
                    }

                    &:nth-child(4) {
                        color: #999999;
                    }
                }

                &:last-child {
                    width: 1%;
                    flex: 1;
                    border-right: none;
                }

                .answer-item:nth-child(1) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 20%;
                    border-bottom: 1px solid #EAEAEA;
                    box-sizing: border-box;
                    font-size: 18px;
                    font-weight: 400;

                    .text-gray {
                        font-weight: 400;
                        color: #999999;
                    }
                }

                .answer-item:nth-child(2) {
                    display: flex;
                    height: 1%;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    font-weight: 400;
                    box-sizing: border-box;
                    /*color: #FF0000;*/
                }
            }
        }

        .paperDetail-content {
            .questionType-item {
                margin-top: 20px;

                .questionType-title {
                    display: flex;
                    align-items: center;

                    .green-block {
                        display: inline-block;
                        width: 5px;
                        height: 20px;
                        background: #2DC079;
                        border-radius: 3px;
                        margin-right: 10px;
                    }

                    .title {
                        font-size: 18px;
                        color: #333;
                        font-weight: 400;
                    }

                    .subtitle {
                        font-size: 18px;
                        color: #999999;
                        margin-left: 10px;
                    }
                }

                .question-item {
                    font-size: 14px;
                    font-weight: 500;
                    /*padding: 22px 40px;*/
                    .question-title {
                        margin-top: 20px;
                        //height: 60px;
                        background: #F7F7F7;
                        line-height: 60px;
                        padding: 0px 40px;
                        //display: flex;
                        //align-items: center;
                        ::v-deep p {
                            display: inline;
                        }
                    }

                    .answer-box {
                        .question-answer {
                            padding: 0 40px;
                            display: flex;
                            flex-direction: column;

                            .answer-item {
                                margin-top: 20px;
                                font-weight: 400;
                                font-size: 14px;
                                color: #333;

                                &.el-radio {
                                    white-space: normal;
                                }

                                ::v-deep .el-radio__input {
                                    white-space: normal;

                                    &.is-checked {
                                        & + .el-radio__label {
                                            color: #333;
                                        }

                                        .el-radio__inner {
                                            border-color: #A0A0A0;
                                            background: #fff;

                                            &::after {
                                                background-color: #A0A0A0;
                                            }
                                        }
                                    }

                                    .el-radio__inner {
                                        &:hover {
                                            border-color: #A0A0A0;
                                        }
                                    }
                                }

                                &.el-checkbox {
                                    white-space: normal;
                                }

                                ::v-deep .el-checkbox__input {
                                    white-space: normal;

                                    &.is-checked {
                                        & + .el-checkbox__label {
                                            color: #333;
                                        }

                                        .el-checkbox__inner {
                                            background-color: #A0A0A0;
                                            border-color: #A0A0A0;
                                        }
                                    }

                                    &.is-indeterminate {
                                        .el-checkbox__inner {
                                            background-color: #A0A0A0;
                                            border-color: #A0A0A0;
                                        }
                                    }

                                    .el-checkbox__inner {
                                        &:hover {
                                            border-color: #A0A0A0;
                                        }
                                    }
                                }
                            }

                            .answer-content {
                                display: flex;
                                flex-wrap: wrap;

                                .answer-item {
                                    margin-left: 30px;

                                    &:first-of-type {
                                        margin-left: 0;
                                    }
                                }

                                //.answer-after{
                                //  &:after{
                                //    content: '、';
                                //  }
                                //}
                                //.answer-after:last-child{
                                //  &:after{
                                //    content: '';
                                //  }
                                //}
                            }
                        }

                        .question-analysis-error {
                            background: #FDF0ED;
                        }

                        .question-analysis-correct {
                            background: #F3FFF8;
                        }

                        .question-analysis {
                            position: relative;
                            margin-top: 50px;
                            padding: 13px 54px;
                            display: flex;
                            flex-wrap: wrap;

                            span {
                                margin-right: 60px;
                                line-height: 1;
                                font-size: 14px;
                                font-weight: 400;

                                .strong-error {
                                    color: #FF0000;
                                }

                                .strong-correct {
                                    color: #1EB788;
                                }

                                .strong-after {
                                    &:after {
                                        content: '、';
                                    }

                                    &:last-child {
                                        &:after {
                                            content: '';
                                        }
                                    }
                                }

                                strong {
                                    font-weight: 400;
                                }
                            }

                            .answer-judge {
                                position: absolute;
                                left: 40px;
                                top: -30px;
                                line-height: 1;
                                padding: 8px 14px;
                                border-radius: 4px 4px 0px 0px;
                            }

                            .answer-judge-error {
                                background: #FDF0ED;
                                color: #FF0000;
                            }

                            .answer-judge-correct {
                                background: #F3FFF8;
                                color: #1EB788;
                            }

                            .isAnalysis {
                                display: flex;
                                align-items: center;

                                span {
                                    cursor: pointer;
                                    line-height: 1;
                                }
                            }
                        }

                        .answer-reference {
                            padding: 18px 40px;
                            display: flex;
                            flex-direction: column;
                            font-size: 14px;
                            color: #666;
                            line-height: 24px;

                            .reference-title {
                                color: #FF0000;
                            }

                            .reference-score {
                                margin-top: 22px;
                                display: flex;
                                align-items: center;

                                ::v-deep .el-input {
                                    margin: 0 10px;

                                    .el-input__inner {
                                        color: #FF0000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .isAnalysis-content {
        padding: 18px 54px;
        border: 1px solid #EEEEEE;
        font-size: 14px;

        .title {
            color: #FF0000;
        }

        .analysis-span {
            ::v-deep p {
                display: inline;
                font-size: 14px;
                color: #333;
            }
        }
    }

    ::v-deep .student-dialog {
        .el-dialog__header {
            padding: 16px 24px;
            background: #2DC079;

            span, i {
                font-size: 16px;
                line-height: 1;
                color: #fff;
            }
        }

        .el-dialog__body {
            padding: 22px 24px;
            height: 300px;
            display: flex;
            flex-direction: column;
        }
    }
</style>